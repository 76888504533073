import { ApplicationState } from "store";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { requestBulkCompleteAll, requestFailedBulk, setTbData } from "store/slices/bulk-slice";

export const useExecuteBulkTb = () => {
    // TODO Handle timeout
    /**Contants */
    const dispatch = useDispatch();

    /**Selectors */
    const loadedBulkList = useSelector((store: ApplicationState) => store.bulkReducer.loadedBulkList);
    const completedBulkList = useSelector((store: ApplicationState) => store.bulkReducer.completedBulkList);
    const bulkPriceList = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceList);
    const bulkPriceRequests = useSelector((store: ApplicationState) => store.bulkReducer.bulkPriceRequests);

    function processAllData() {
        let allSuccess = true;
        for (const key in loadedBulkList) {
            const tbData = loadedBulkList[key];
            // find matching priceList - has request
            let matchingRequestName = null;
            let matchingEventId = null;
            for (const req of bulkPriceList) {
                if (req.streams.length > 0) {
                    for (const stream of req.streams) {
                        if (stream.eventId === key) {
                            matchingRequestName = req.name;
                            matchingEventId = stream.eventId;
                            break;
                        }
                    }
                } else {
                    if (req.eventId === key) {
                        matchingRequestName = req.name;
                        matchingEventId = req.eventId;
                        break;
                    }
                }

            }
            if (matchingRequestName && matchingEventId) {
                const success = processWebSocketData(tbData, matchingEventId, matchingRequestName);
                if (!success) {
                    allSuccess = false;
                }
            }
        }
        if (allSuccess) {
            // failure could be on json - sets error there
            // this completes all bulk items
            dispatch(requestBulkCompleteAll());
        }
    }

    function processWebSocketData(tbData: any, eventId: string, name: string) {
        let tbJson = null;
        try {
            tbJson = JSON.parse(tbData)
        } catch (e) {
            console.error(`Error parsing : ${e}`)
        }
        if (tbJson) {
            // error handling is in websocket-saga.ts
            tbJson["name"] = name;
            responseSuccess(tbJson, eventId);
            return true;
        } else {
            responseDoneWithError({ "error": { "message": "Unable to parse data", "details": "JSON.parse() failed" } });
            return false;
        }
    }

    const responseDoneWithError = (data: any) => {
        const message = data.error.message;
        const details = data.error.details;
        console.log('data error', data)
        dispatch(requestFailedBulk({ message, details }));
    }

    const responseSuccess = (data: any, eventId: string) => {
        // convert data and save
        // console.log('data success', data, 'eventId', eventId)
        dispatch(setTbData({data: data, eventId: eventId})); 
    }

    useEffect(() => {
        const completedLen = Object.keys(completedBulkList).length;
        if (completedLen > 0 && completedLen === bulkPriceRequests &&
            Object.values(completedBulkList).every((value) => value === true)) {
            // completed requests match requested length and all are true
            processAllData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completedBulkList]);

}
