import classes from './style.module.scss';
import { useAuth } from 'common/hooks/useAuth';
import { useAxiosInterceptors } from 'common/adapters/api';

interface AdminProps {
    getAypaToken: Function
}

export const ReleaseNotes = ({ getAypaToken }: AdminProps) => {
    useAxiosInterceptors(getAypaToken);
    const { isValid } = useAuth({ getAypaToken });

    const releaseData = [
       {
          version: 'v3.4.5',
          date: '2024-01-29',
          changes: [
            { type: 'Added', description: `New navigation header. Named the main price search to 'Home'.`},
            { type: 'Added', description: 'A Bulk page on top navigation. This allows to search and run Top Bottom analysis on up to 100 nodes. It will run Real Time and Day Ahead prices for each Node/LMP.' +
              '  It has the ability to download results.'
            },
            { type: 'Improved', description: `Home - NRG Stream - Removed 'Location' in the wildcard search.  Searching by location returned too many results to be helpful.` },
            { type: 'Improved', description: 'Price/Perfect Foresight Downloads - added Start and End date to metadata.' },
          ]
      },
      {
        version: 'v3.3.10',
        date: '2024-11-20',
        changes: [
          { type: 'Added', description: 'Aypa Nodal and NRG Stream - Nodal search now has an exact search.  Use quotes around text you are searching.  For example, "South".'},
          { type: 'Added', description: 'Download - Added "Active" column to Node/LMP export' },
          { type: 'Added', description: 'Perfect Foresight - Revenue Tables can be downloaded.' },
          { type: 'Improved', description: 'Aypa Zonal - Changed upload source to be AWS S3 and use new AWS services to process the files more efficiently.'}
        ]
    },
        {
            version: 'v3.3.9',
            date: '2024-11-04',
            changes: [
              { type: 'Added', description: 'Download - Ability to export Node/LMP data for a given Region/ISO.  Get Price for Aypa Nodal/NRG Stream -> Download -> Download Nodes for Region. ' +
                'This report will have columns: region, location, node, substation, lat, and lon. The limit is 2 different regions.  Aypa Nodal ERCOT and NRG Stream ERCOT count as different regions.'
              },
              { type: 'Added', description: 'NRG Stream - Added location name from Energy Velocity dataset. The LMP dropdown displays Location instead of Region. ' +
                'Can filter by location name in the LMP dropdown.  If LMP name does not have a match in Energy Velocity, then location will be the ISO name. ' +
                'These locations are included on the new Download Nodes for Region.'
              },
              { type: 'Added', description: 'NRG Stream - Added an Active LMP toggle.  The default is to only display active LMPs.  Turning off the switch will display active and inactive LMPs. ' +
                'Inactive LMPs will have the last recorded price as the end date.  This data is from NRG Stream.'
              },
            ]
        },
        {
            version: 'v3.3.6',
            date: '2024-10-22',
            changes: [
              { type: 'Added', description: 'NRG Stream - Added closest substation to each LMP from Energy Velocity data.  LMP drop down can be filtered by substation name.'},
              { type: 'Added', description: 'Add MS Teams Message when custom upload has started.'},
              { type: 'Fixed', description: 'Price Data - Raw tab was not refreshing data when new price was added.' },
              { type: 'Fixed', description: 'Perfect Foresight - Charts would sometime disappear when going back to Price charts.' },
              { type: 'Fixed', description: 'Perfect Foresight - Charts would display NaN for large numbers.' }
            ]
        },
        {
            version: 'v3.3.4',
            date: '2024-09-19',
            changes: [
              { type: 'Fixed', description: 'Aypa Nodal - Perfect Foresight and Fixed Schedule bug fix.' }
            ]
        },
        {
          version: 'v3.3.3',
          date: '2024-09-09',
          changes: [
            { type: 'Added', description: 'Aypa Nodal - Added closest substation to each Node from Energy Velocity data.  Node drop down can be filtered by substation name.'},
            { type: 'Improved', description: 'Aypa Nodal - Bus Number and Bus Name are now distinct in the Node drop down.  Can be filtered by either.' },
            { type: 'Fixed', description: 'When searching by name, the “_” character was being treated as a wildcard instead of matching exactly.' }
          ]
        },
        {
          version: 'v3.2.16',
          date: '2024-08-12',
          changes: [
            { type: 'Added', description: 'On each Price Graph, the average price for each data set is displayed.'},
            { type: 'Added', description: 'Ability to delete forecasts (Aypa Nodal, Aypa Zonal, and Wood Mac) from the Admin Tools page.'},
            { type: 'Improved', description: 'On the graphs there are labels for each dataset.  Clicking on a label will now hide all other datasets and only show the selected label.'},
            { type: 'Fixed', description: 'Microsoft connectors to Teams is deprecated.  Migrated to Workflows for Teams messages' }
          ]
        },
        {
          version: 'v3.2.14',
          date: '2024-07-18',
          changes: [
            { type: 'Improved', description: `Updated the Top Bottom (TB) function from the Commercial team to v2. 
                There was an issue where the charge cost was too low if the total number of charging intervals was a whole number. 
                The code will now look at the max TB across durations less than or equal to what is entered.  
                For instance, if a duration of 4 is entered, the code will run TB's for 1, 2, 3, and 4 hour and pick max one by day.`},
            { type: 'Improved', description: 'Round Trip Efficiency changed to have 1 significant digit.  For Example: 88.5%.'},
            { type: 'Improved', description: 'Updated the Price List to have a max of 10 (up from 5).'},
            { type: 'Improved', description: 'Updated React to v18, Nodejs to v20 and all associated packages.'},
            { type: 'Improved', description: 'Updated the number of colors for each graph to 12 from 8.'},
            { type: 'Improved', description: 'Added a +/- Zoom button on the Raw price tab.'},
            { type: 'Improved', description: 'The download button now retrieves the data that is being displayed on the current graph.  It was always downloading the raw data.  To get the raw data, download from the raw tab.  '},
            { type: 'Improved', description: 'Updated backend code from Python3.8 to Python3.12 and all libraries.'},
            { type: 'Fixed', description: 'Microsoft Token was not auto refreshing once it expired.' },
            { type: 'Fixed', description: 'Selecting the Peak button would reset to the Overall tab instead of the tab the user was viewing.'}
          ]
        },
        {
            version: 'v3.2.8 hotfix',
            date: '2024-06-12',
            changes: [
              { type: 'Fixed', description: `The source files for Aypa Nodal are hour-starting.  It was being saved as hour-ending.  Changed how the source files are saved in the app, deleted all 
                existing files, and uploaded Aypa Nodal files again.` }
            ]
        },
        {
            version: 'v3.2.8',
            date: '2024-5-31',
            changes: [
                { type: 'Added', description: 'New graphs: Raw Tab - displays raw data and has zoom feature'},
                { type: 'Added', description: 'New graphs: Seasonal Tab - replaced Quarterly with Seasonal for Summer/Winter'},
                { type: 'Added', description: 'New graphs: Can set Peak and Off Peak hours for all price graphs.'},
                { type: 'Added', description: 'Ability to display multiple prices on the same graph.  Added Price List with a max of 5.  Can add any combination of sources.  Simulations only work for 1 price at a time.'},
                { type: 'Improved', description: 'Combined all download buttons into one button with a drop-down menu.'},
                { type: 'Improved', description: 'Moved all services to new AWS account' },
                { type: 'Improved', description: 'The download file will retrieve data for all prices that are being displayed now that it can show multiple prices.'},
                { type: 'Improved', description: 'Updated the colors for each graph to be more diverse.'},
                { type: 'Fixed', description: 'Issues with very large Zonal data in Microsoft SharePoint.' },
                { type: 'Fixed', description: 'Set a min/max height for all graphs and fixed an issue with overlapping graphs.' },
            ]
        },
        {
            version: 'v3.1.11',
            date: '2024-04-03',
            changes: [
                { type: 'Added', description: 'An Admin Tools page that can invoke the source data ingestion process.'},
                { type: 'Improved', description: 'Update backend terraform version from v3 to v5.'},
                { type: 'Improved', description: 'Revenue Charts will display data better when only 1 year is selected.' },
                { type: 'Improved', description: `Revenue Charts will Cycle Count rounded to 1 decimal place and 'Revenues' rounded to nearest dollar. `},
                { type: 'Fixed', description: 'Issues with very large Zonal data in AWS lambda.' },
                { type: 'Fixed', description: 'Standardized the Aypa Nodal columns in the source data.' },
                { type: 'Fixed', description: 'Updated NRG Service backend.' },
                { type: 'Fixed', description: `Made references to Date and Time to be consistent.  The app uses 'timestamp' everywhere now.` },
            ]
        },
        {
            version: 'v3.1.9',
            date: '2024-03-15',
            changes: [
                { type: 'Fixed', description: `Start to End date calculation was off by 1 day.  Used to verify if 5min data was >5 years.` },
                { type: 'Fixed', description: `Loading gif issue on perfect foresight` },
                { type: 'Fixed', description: `Cancel button on a request did not reestablish a connection.` },
            ]
        },
        {
            version: 'v3.1.8',
            date: '2024-03-12',
            changes: [
                { type: 'Added', description: `Perfect Foresight - uses the 'Perfect Foresight' linear programming optimization model from the commercial team.  
                    Runs the Top Bottom v1 function from the commercial team.  This is a more advanced optimization than the Fixed Schedule model.`},
                { type: 'Improved', description: `Updated security on frontend and backend.` },
                { type: 'Improved', description: `Added a timeout and Cancel button to Price and Perfect Foresight requests.` },
                { type: 'Improved', description: `Simulations for Aypa Nodal, Aypa Zonal, and Wood Mac can be triggered without displaying the Prices first.  Select a forecast and then select Perfect Foresight to run.  
                    NRG Stream still needs to display the Prices first.` },
                { type: 'Improved', description: `Made End Date Inclusive.` },
                { type: 'Improved', description: `Added metadata to all downloads.` },
                { type: 'Fixed', description: `Issues with very large amount of Zonal data files.` },
                { type: 'Fixed', description: `Perfect Foresight rows were not sorted by datetime.` },
                { type: 'Fixed', description: `Unable to download files larger than 25MB.` },
                { type: 'Fixed', description: `Fixed Schedule was failing to run using 5min data from NRG Stream.` },
            ]
        }
      ];

    return (
        <>
            { isValid &&
                <>
                <div className={classes.releaseNotesContainer}>
                <h1>Release Notes</h1>
                {releaseData.map((release, index) => (
                    <div key={index} className={classes.releaseNote}>
                    <div className={classes.releaseNoteHeader}>
                        <div className={classes.version}>{release.version}</div>
                        <div className={classes.date}>{release.date}</div>
                    </div>
                    <ul className={classes.changeList}>
                        {release.changes.map((change, idx) => (
                        <li key={idx}>
                            <span className={classes.changeType}>{change.type}:</span> {change.description}
                        </li>
                        ))}
                    </ul>
                    </div>
                ))}
                </div>
    
                </>
            }
        </>
    )
}
